<template>
  <div>
    <Table
      title-key="code"
      :actions="actions"
      :items="$store.getters['subscription/tableData']"
      :fields="$store.getters['subscription/tableFields']"
      :loading="$store.state.subscription.loadingTable"
      :filter="$store.state.subscription.tableFilter"
      :sort="$store.state.subscription.tableSort"
      :sort-options="sortOptions"
      :keyword="$store.state.subscription.tableKeyword"
      :pagination="$store.state.subscription.tablePagination"
      search-placeholder="Search creator, order number"
      @changeFields="changeFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
      @download="downloadData"
    />

    <!-- Cancel auto-renewal confirmation modal -->
    <b-modal
      v-model="cancelAutoRenewalModalVisible"
      title="Cancel auto-renewal"
      ok-title="Yes, cancel"
      cancel-variant="white"
      :ok-disabled="loadingSubmitModal"
      @ok="cancelAutoRenewal"
    >
      Are you sure want to cancel this subscription auto-renewal?.
      The subscription will still be active until the period ends.
    </b-modal>

    <!-- Reactivate auto-renewal confirmation modal -->
    <b-modal
      v-model="reactivateAutoRenewalModalVisible"
      title="Reactivate auto-renewal"
      ok-title="Yes, reactivate"
      reactivate-variant="white"
      :ok-disabled="loadingSubmitModal"
      @ok="reactivateAutoRenewal"
    >
      Are you sure want to reactivate this subscription auto-renewal?.
    </b-modal>

    <!-- Deactive Subscription confirmation modal -->
    <b-modal
      v-model="deactivateSubscriptionModalVisible"
      title="Deactive subscription"
      ok-title="Yes, deactive"
      reactivate-variant="white"
      :ok-disabled="loadingSubmitModal"
      @ok="deactivateSubscription"
    >
      It will deactivate the subscription right away. Are you sure?
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      cancelAutoRenewalModalVisible: false,
      reactivateAutoRenewalModalVisible: false,
      deactivateSubscriptionModalVisible: false,
      loadingSubmitModal: false,
      selectedData: '',

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],
      actions: [
        'view',
        'download',
        {
          type: 'other',
          children: [
            {
              label: 'Cancel auto-renewal',
              event: this.confirmCancelAutoRenewal,
              visibility: {
                callback: data => !data.isCancelled
                  && data.isActive
                  && data.isRenewal
                  && this.canAccess('manage', 'Subscription'),
              },
            },
            {
              label: 'Deactivate subscription',
              event: this.confirmDeactivateSubscription,
              visibility: {
                callback: data => data.isActive
                  && this.canAccess('manage', 'Subscription'),
              },
            },
            {
              label: 'Reactivate',
              event: this.confirmReactivateAutoRenewal,
              visibility: {
                callback: data => data.isCancelled
                  && data.isActive
                  && data.isRenewal
                  && this.canAccess('manage', 'Subscription'),
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    changeFields(val) {
      this.$store.commit('subscription/SET_TABLE_FIELDS', val)
    },
    loadData() {
      return this.$store.dispatch('subscription/getTableData')
    },
    filterData(val) {
      this.$store.commit('subscription/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('subscription/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('subscription/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('subscription/SET_TABLE_KEYWORD', val)
    },
    cancelAutoRenewal(e) {
      e.preventDefault()
      this.loadingSubmitModal = true

      this.$store.dispatch('subscription/cancel', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Cancel auto-renewal success!',
              variant: 'success',
            },
          })

          this.cancelAutoRenewalModalVisible = false
          this.loadData()
        })
        .finally(() => {
          this.loadingSubmitModal = false
        })
    },
    confirmCancelAutoRenewal(data) {
      this.selectedData = data.item
      this.cancelAutoRenewalModalVisible = true
    },
    reactivateAutoRenewal(e) {
      e.preventDefault()
      this.loadingSubmitModal = true

      this.$store.dispatch('subscription/reactivate', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Reactivate auto-renewal success!',
              variant: 'success',
            },
          })

          this.reactivateAutoRenewalModalVisible = false
          this.loadData()
        })
        .finally(() => {
          this.loadingSubmitModal = false
        })
    },
    confirmReactivateAutoRenewal(data) {
      this.selectedData = data.item
      this.reactivateAutoRenewalModalVisible = true
    },
    deactivateSubscription(e) {
      e.preventDefault()
      this.loadingSubmitModal = true

      this.$store.dispatch('subscription/deactivate', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Deactive subscription success!',
              variant: 'success',
            },
          })

          this.deactivateSubscriptionModalVisible = false
          this.loadData()
        })
        .finally(() => {
          this.loadingSubmitModal = false
        })
    },
    confirmDeactivateSubscription(data) {
      this.selectedData = data.item
      this.deactivateSubscriptionModalVisible = true
    },
    downloadData() {
      return this.$store.dispatch('subscription/downloadTableData')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Your data will be ready in a minutes!',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'export-result' })
        })
    },
  },
}
</script>
